import React from 'react'
import schema from '_schema/cookieItem'
import routes from './routes'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { Form } from 'pages'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const profile = useSelector(state => state.profile)
  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={800}
      modifyProperties={properties => {
        return addOpenformProperties(profile.isOpenform, properties)
      }}
    />
  )
}
