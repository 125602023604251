const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      isExhibitAdditionalPropertiesVisible: {},
      isExhibitAdditionalDescriptionPropertyVisible: {},
      isExhibitFeaturePropertyVisible: {},
      isExhibitArchetypePropertyVisible: {},
      isExhibitAnalogyPropertyVisible: {},
      isExhibitCyclePropertyVisible: {},
      isExhibitStickersPropertyVisible: {},
      isExhibitAdditionalTitlePropertyVisible: {},
      isExhibitAttributionPropertyVisible: {},
      isExhibitPersonElaborationPropertyVisible: {},
      isExhibitWeightPropertyVisible: {},
      isExhibitAdditionalDimensionsPropertyVisible: {},
      isExhibitFormatPropertyVisible: {},
      isExhibitOsPropertyVisible: {},
      isExhibitRarityPropertyVisible: {},
      importerNotificationEmails: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },
    },
  },
  forms: {
    cookies: {
      definition: '#Config',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            cookiesBoxTitle: {
              hint: 'tytuł boxa',
            },
            cookiesBoxMsg: {
              type: 'textarea',
              hint: 'główny tekst w boxie',
            },
          },
        },
      },
    },
  },
}

export default schema
