import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { DateFilter } from 'components/table/filters/DateFilter'
import { translate } from '_helpers/translate'
import schema from '_schema/cookieIp'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'IP',
        accessor: 'ip',
        filterable: true,
        sortable: false,
      },

      {
        header: 'Data dodania',
        accessor: 'createdAt',
        width: '12%',
        Cell: DatetimeCell,
        sortable: true,
      },
      {
        ...operations('ip', true, true, false, true),
        width: '7%',
      },
    ],
    []
  )

  const customFilters = [
    {
      name: 'createdAt[after]',
      title: 'Data od',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'createdAt[before]',
      title: 'Data do',
      value: null,
      Filter: DateFilter,
    },
  ]

  return (
    <Paper>
      <CollectionHeader hideButton={true}>
        {translate('Lista cookie IP')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'userAgent',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'cookies',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'referer',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt[after]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt[before]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[referer]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
      />
    </Paper>
  )
}
