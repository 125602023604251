import React, { useMemo, useEffect, useState } from 'react'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound, BlankForm } from 'pages'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { publish } from 'components/table/_mass'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import schema from '_schema/cookieItem'
import configSchema from '_schema/config'
import routes from './routes'
import { LANGS_IRI } from '_lib/langs'

export const Collection = ({ schema: definitions }) => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        width: '73%',
      },

      {
        ...operations(`translations.${LANGS_IRI.PL}.title`),
        width: '15%',
      },
    ],
    []
  )

  const [configState, setConfigState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      configSchema.endpoint,
      'GET',
      { signal },
      response => {
        setConfigState({
          isFetching: false,
          resource: response,
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setConfigState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [])

  const handleConfigSuccess = resource => {
    setConfigState(state => ({
      ...state,
      resource,
    }))
  }

  const {
    isFetching: isConfigFetching,
    fetchError: configFetchError,
    resource: configResource,
  } = configState

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj grupę')}
        buttonPath={routes().new.path}
      >
        {translate('Cookies')}

        <div style={{ fontSize: 'initial', marginTop: '2em' }}>
          {isConfigFetching ? (
            <Loader />
          ) : configFetchError ? (
            <NotFound />
          ) : (
            <BlankForm
              resource={configResource}
              definitionSchema={definitions[configSchema.resource.definition]}
              customResourceSchema={configSchema.forms.cookies}
              method={configResource['@id'] ? 'PUT' : 'POST'}
              url={configResource['@id'] || configSchema.endpoint}
              handleSuccess={handleConfigSuccess}
              showSubmitAndStayButton={false}
              showCancelButton={false}
              key={configResource['@id'] || 'init'}
              fieldsFullWidth={true}
              buttonsFixed={false}
              width={700}
            />
          )}
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'idName',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'translations.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
