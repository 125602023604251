import React, { useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { EmbeddedCollection } from 'components/embedded'
import { StaticCollection } from 'components/static'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 30,
    marginTop: 30,
  },
}))

export const EmbeddedCollectionType = ({
  uuid,
  label,
  value,
  properties,
  definitionRef = null,
  endpoint,
  formUrl,
  titleAccessor = null,
  disabled = false,
  classes = {},
  statable = false,
  addTitle = null,
  expandTitle = null,
  panelTitle = null,
  types = null,
  width = '100%',
  ...rest
}) => {
  const schema = useSelector(state => state.schema)

  const definitionSchema = useMemo(
    () => (definitionRef ? schema.definitions[definitionRef] : schema),
    [schema, definitionRef]
  )

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {disabled ? (
        <StaticCollection
          items={value || []}
          properties={properties}
          definitionSchema={definitionSchema}
        />
      ) : (
        uuid && (
          <EmbeddedCollection
            endpoint={endpoint}
            pid={uuid}
            parentIri={formUrl}
            properties={properties}
            definitionSchema={definitionSchema}
            alignButtonEvenly={true}
            titleAccessor={titleAccessor}
            disabled={!uuid || disabled}
            headerTitle={translate(label?.text || label)}
            statable={statable}
            addTitle={addTitle}
            expandTitle={expandTitle}
            panelTitle={panelTitle}
            types={types}
            formWidth={width}
            {...rest}
          />
        )
      )}
    </div>
  )
}
