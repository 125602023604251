import React, { useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
  makeStyles,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  BodySkeleton,
  TableLoader,
  PaginationActions,
  useCollectionTableStyles,
} from 'components/table'
import { MassSelectCheckbox } from './MassSelectCheckbox'
import { MassSelectChoice } from './MassSelectChoice'
import { useChooseTableFetch } from './_helpers/useChooseTableFetch'
import { FilterBar } from './FilterBar'
import { translate } from '_helpers/translate'
import { constants, reducer } from './_state'
import { LANGS_IRI } from '_lib/langs'
import { ExhibitCell } from './ExhibitCell'
import { operations } from './_mass/add'

const useStyles = makeStyles({
  table: {
    marginTop: 15,
  },
  container: {
    overflowX: 'unset',
  },
})

export const ChooseTable = ({
  parent,
  endpoint,
  viewPath,
  isSelectable,
  perPage = null,
  perPageOptions = null,
}) => {
  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        width: '88%',
      },
    ],
    []
  )

  const appDispatch = useDispatch()

  const [state, dispatch] = useChooseTableFetch(
    reducer,
    endpoint,
    {
      creator: {
        name: 'iri[creators.creator]',
        value: [],
      },
      type: {
        name: 'dictionary[types.type]',
        value: [],
      },
      material: {
        name: 'dictionary[materials.material]',
        value: [],
      },
      technique: {
        name: 'dictionary[techniques.technique]',
        value: [],
      },
      place: {
        name: 'dictionary[places.place]',
        value: [],
      },
      keyword: {
        name: 'dictionary[keywords.keyword]',
        value: [],
      },
      yearFrom: {
        name: 'yearFrom[gte]',
        value: null,
      },
      yearTo: {
        name: 'yearTo[lte]',
        value: null,
      },
      division: {
        name: 'iri[division]',
        value: [],
      },
      exhibition: {
        name: 'iri[exhibitions.exhibition]',
        value: [],
      },
      museumId: {
        name: 'museumId',
        value: null,
      },
    },
    perPage
  )

  const setFilters = useCallback(
    (rawFilters, filters) =>
      dispatch({
        type: constants.SET_FILTERS,
        payload: { rawFilters, filters },
      }),
    [dispatch]
  )

  const handleReset = useCallback(() => {
    dispatch({ type: constants.RESET })
  }, [dispatch])

  const setPage = useCallback(
    (e, page) =>
      dispatch({ type: constants.SET_PAGE, payload: { page: page + 1 } }),
    [dispatch]
  )

  const setPerPage = useCallback(
    e => {
      const perPage = e.target.value
      dispatch({ type: constants.SET_PER_PAGE, payload: { perPage } })
    },
    [dispatch]
  )

  const handleSingleSelect = useCallback(
    row => () =>
      dispatch({ type: constants.SET_SINGLE_SELECT, payload: { row } }),
    [dispatch]
  )

  const defaultClasses = useCollectionTableStyles()
  const classes = useStyles()

  const columnsAmount = columns.length + 2

  return (
    <TableContainer
      className={clsx(defaultClasses.container, classes.container)}
    >
      <TableLoader show={!state.init && state.isFetching} />
      <FilterBar
        rawFilters={state.config.rawFilters}
        setFilters={setFilters}
        handleReset={handleReset}
        disabled={state.isInit || state.isFetching}
      />
      {isSelectable && (
        <MassSelectChoice
          selectable={[operations.add(parent, appDispatch)]}
          tableState={state}
          tableStateDispatch={dispatch}
          classes={{
            container: classes.massSelectContainer,
            title: classes.massSelectContainerTitle,
          }}
        />
      )}
      <Table size="small" className={classes.table}>
        <TableHead className={defaultClasses.tHead}>
          <TableRow>
            <TableCell key="header-mass" style={{ width: '5%' }}>
              {isSelectable && (
                <div
                  className={clsx(
                    defaultClasses.massHeadColumn,
                    classes.massHeadColumn
                  )}
                >
                  <MassSelectCheckbox
                    tableState={state}
                    tableStateDispatch={dispatch}
                  />
                </div>
              )}
            </TableCell>
            <TableCell
              key="header-number"
              style={{
                width: '7%',
              }}
            >
              {translate('T_GENERAL_NO')}
            </TableCell>
            {columns.map((column, i) => (
              <TableCell key={`header-${i}`} style={{ width: column.width }}>
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.init ? (
            <BodySkeleton rows={state.config.perPage} columns={columnsAmount} />
          ) : state.data.items.length > 0 ? (
            state.data.items.map((item, i) => {
              const handleRowClick = e => {
                if (e.target.tagName === 'TD') {
                  history.push(viewPath.replace(':id', item.uuid))
                }
              }

              return (
                <TableRow
                  onClick={handleRowClick}
                  className={clsx(
                    defaultClasses.row,
                    classes.row,
                    clsx(defaultClasses.rowAsLink, classes.rowAsLink)
                  )}
                  index={i}
                  key={`${item.uuid}${`-${Date.now()}`}`}
                >
                  <TableCell key="header-mass" style={{ width: '5%' }}>
                    <div
                      className={clsx(
                        defaultClasses.massColumn,
                        classes.massColumn
                      )}
                    >
                      {isSelectable && (
                        <Checkbox
                          checked={!!state.data.selected[i]}
                          onChange={handleSingleSelect(i)}
                          name="mass"
                          color="secondary"
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell key="column-number" style={{ width: '7%' }}>
                    {(state.data.page - 1) * state.data.perPage + i + 1}
                  </TableCell>
                  {columns.map((column, j) => (
                    <TableCell
                      className={clsx(defaultClasses.cell, classes.cell)}
                      key={`column-${j}`}
                      style={{ width: column.width }}
                    >
                      <ExhibitCell
                        resource={item}
                        tableState={state}
                        tableStateDispatch={dispatch}
                        viewPath={viewPath}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnsAmount}
                className={clsx(
                  defaultClasses.emptyResults,
                  classes.emptyResults
                )}
              >
                {translate('T_TABLE_NO_RESULTS')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={state.init ? 0 : state.data.page - 1}
              count={state.data.totalItems}
              onPageChange={setPage}
              onRowsPerPageChange={setPerPage}
              rowsPerPage={state.data.perPage}
              rowsPerPageOptions={
                perPageOptions ||
                process.env.REACT_APP_COLLECTION_PER_PAGE_OPTIONS.split(
                  ','
                ).map(option => parseInt(option.trim()))
              }
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
