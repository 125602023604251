import adminSchema from '_schema/admin'
import adminGroupSchema from '_schema/adminGroup'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'
import dictionaryBook from '_schema/dictionaryBook'
import dictionaryRole from '_schema/dictionaryRole'
import dictionaryDivision from '_schema/dictionaryDivision'
import dictionaryLocation from '_schema/dictionaryLocation'
import dictionaryPlace from '_schema/dictionaryPlace'
import dictionaryTechnique from '_schema/dictionaryTechnique'
import dictionaryMaterial from '_schema/dictionaryMaterial'
import dictionaryType from '_schema/dictionaryType'
import dictionaryKeyword from '_schema/dictionaryKeyword'
import dictionaryPublication from '_schema/dictionaryPublication'
import dictionaryExhibition from '_schema/dictionaryExhibition'
import creator from '_schema/creator'
import exhibit from '_schema/exhibit'
import collection from '_schema/collection'
import importEntry from '_schema/importEntry'
import cookieItem from '_schema/cookieItem'

const resources = () => ({
  [page.access]: 'Pages',
  [creator.access]: 'Creators',
  [exhibit.access]: 'Exhibits',
  [collection.access]: 'Collections',
  [dictionaryBook.access]: 'Dictionary books',
  [dictionaryRole.access]: 'Dictionary roles',
  [dictionaryDivision.access]: 'Dictionary divisions',
  [dictionaryLocation.access]: 'Dictionary locations',
  [dictionaryPlace.access]: 'Dictionary places',
  [dictionaryTechnique.access]: 'Dictionary techniques',
  [dictionaryMaterial.access]: 'Dictionary materials',
  [dictionaryType.access]: 'Dictionary types',
  [dictionaryKeyword.access]: 'Dictionary keywords',
  [dictionaryPublication.access]: 'Dictionary publications',
  [dictionaryExhibition.access]: 'Dictionary exhibitions',
  [adminSchema.access]: 'Administrators',
  [adminGroupSchema.access]: 'Administrator Groups',
  [loginEntry.access]: 'Login entries',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  [importEntry.access]: 'Import entries',
  [cookieItem.access]: 'Cookies',
})

export default resources
