import React from 'react'
import { useState } from 'react'
import { UploadDialog } from 'components/form/fields/file/UploadDialog'
import { Button } from '@material-ui/core'
import mediaSchema from '_schema/media'

export const UploadOrdCsvDialog = ({
  iri,
  resource,
  setResource,
  disabled,
}) => {
  const setValue = processedMedia => {
    setResource({
      ...resource,
      processedMedia,
    })
  }

  const handleOrdCsvProcessing = response => {
    setResource({
      ...resource,
      ordCsvProcessState: response.ordCsvProcessState,
    })
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleToggle}
        disabled={disabled}
      >
        Wgraj plik do przetworzenia
      </Button>
      <UploadDialog
        name="ordCsvProcessedMedia"
        type="file"
        endpoint={mediaSchema.endpoint}
        setValue={setValue}
        formUrl={iri}
        formMethod="PUT"
        accept="text/csv"
        isOpen={isOpen}
        handleToggle={handleToggle}
        disabled={disabled}
        formProcessResponse={handleOrdCsvProcessing}
      />
    </>
  )
}
