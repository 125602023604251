const schema = {
  endpoint: '/api/cookie_ips',
  access: 'cookieItem',
  resource: {
    definition: '#CookieIp',
    properties: {
      ip: {
        label: 'IP',
      },
      userAgent: {
        label: 'Przeglądarka',
        type: 'textarea',
      },
      cookies: {
        label: 'Zapisane cookies',
        type: 'textarea',
      },
      referer: {
        label: 'Skąd akcja',
        type: 'string',
      },
    },
  },
}

export default schema
