import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'components/table'
import { Form } from 'components/form'
import { ContextualHint } from 'components/ContextualHint'
import { BodySkeleton, TableLoader } from 'components/table'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { Add } from './Add'
import { useEmbeddedCollectionFetch } from './_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from './_helpers/performOrderChange'
import { applyProperties } from '_helpers/applyProperties'
import { prop } from '_helpers/prop'
import { useEmbeddedCollectionStyles, embeddedCollectionPropTypes } from '.'
import { translate } from '_helpers/translate'
import { constants, reducer } from './_state'
import { boolean } from '_helpers/boolean'
import { LANGS_IRI } from '_lib/langs'

export const EmbeddedCollection = ({
  endpoint,
  parentIri,
  properties: customProperties,
  definitionSchema,
  headerTitle = null,
  expandTitle = null,
  panelTitle = null,
  titleAccessor = null,
  statable = false,
  statAccessor = 'stat',
  contextualHint = null,
  disabled = false,
  classes = {},
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(
    reducer,
    endpoint,
    parentIri
  )

  const defaultClasses = useEmbeddedCollectionStyles()

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const properties = applyProperties(
    customProperties,
    definitionSchema.properties,
    'missing'
  )

  return (
    <>
      <div className={clsx(defaultClasses.header, classes.header)}>
        <div>{headerTitle}</div>
        <div
          className={clsx(defaultClasses.massExpand, classes.massExpand)}
          onClick={handleExpand}
        >
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer
        className={clsx(
          defaultClasses.collectionContainer,
          classes.collectionContainer
        )}
      >
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                const handleSyncUpdatedAt = resource => {
                  dispatch({
                    type: constants.SYNC_UPDATED_AT,
                    payload: {
                      iri: resource['@id'],
                      updatedAt: resource.updatedAt,
                    },
                  })
                }

                return (
                  <ManuallyOrderableTableRow index={i} key={`${item.uuid}`}>
                    <TableCell
                      key="item"
                      className={clsx(defaultClasses.cell, classes.cell)}
                      style={{ width: '100%' }}
                    >
                      <Accordion
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <AccordionSummary
                          classes={{
                            content: clsx(
                              defaultClasses.expansionPanelSummary,
                              classes.expansionPanelSummary
                            ),
                          }}
                        >
                          <ManualOrderDragHandle />
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.itemTitle,
                              classes.itemTitle
                            )}
                          >
                            <span>{panelTitle ? `${panelTitle}: ` : ''}</span>
                            {titleAccessor
                              ? typeof titleAccessor === 'function'
                                ? titleAccessor(item)
                                : prop(item, titleAccessor)
                                ? prop(item, titleAccessor)
                                : translate('T_GENERAL_MISSING_TITLE')
                              : item.translations?.[LANGS_IRI.PL]?.title
                              ? item.translations?.[LANGS_IRI.PL]?.title
                              : translate('T_GENERAL_MISSING_TITLE')}
                          </Typography>
                          {statable && (
                            <div
                              className={clsx(
                                defaultClasses.itemIcon,
                                classes.itemIcon
                              )}
                            >
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={state.isFetching}
                                handleSyncUpdatedAt={handleSyncUpdatedAt}
                              />
                            </div>
                          )}
                          <div
                            className={clsx(
                              defaultClasses.itemIcon,
                              classes.itemIcon
                            )}
                          >
                            <Delete
                              resource={item}
                              accessor={
                                titleAccessor ||
                                `translations.${LANGS_IRI.PL}.title`
                              }
                              disabled={state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.expandIcon,
                              classes.expandIcon
                            )}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{
                            root: clsx(
                              defaultClasses.itemContainer,
                              classes.itemContainer
                            ),
                          }}
                        >
                          <Form
                            url={item['@id']}
                            method="PUT"
                            properties={properties}
                            resource={item}
                            width={800}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            disabled={disabled}
                            key={`${item.uuid}-${i}`}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer, classes.addContainer)}>
        <Add
          endpoint={endpoint}
          parent={parentIri}
          dispatch={dispatch}
          disabled={disabled || state.isFetching}
        />
        {contextualHint && (
          <div
            className={clsx(
              defaultClasses.contextualHint,
              classes.contextualHint
            )}
          >
            <ContextualHint name={contextualHint} />
          </div>
        )}
      </div>
    </>
  )
}

EmbeddedCollection.propTypes = embeddedCollectionPropTypes
