import { LANGS_IRI } from '_lib/langs'

const schema = {
  endpoint: '/api/cookie_items',
  resource: {
    definition: '#CookieItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: { label: 'Nazwa grupy' },
          shortLead: { type: 'textarea' },
        },
      },
      required: {},
      stat: {},
      script: { type: 'textarea' },
      options: {
        type: 'embedded_collection',
        endpoint: '/api/cookie_item_options',
        definitionRef: '#CookieItemOption',
        description: 'Poszczególne cookies',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              provider: {},
              shortLead: {
                type: 'textarea',
              },
              date: {},
              kind: {},
            },
          },

          stat: {},
        },
      },
    },
  },
  forms: {
    add: {
      definition: '#CookieItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: { label: 'Nazwa grupy' },
            shortLead: { type: 'textarea' },
          },
        },
      },
    },
  },
}

export default schema
