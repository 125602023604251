import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'pages/BlankForm'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { Sidebar as OriginalSidebar } from 'components/resource/sidebar'
import { translate } from '_helpers/translate'
import { boolean } from '_helpers/boolean'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const EditForm = ({
  resource,
  setResource,
  iri = null,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  wrapWithPaper = true,
  header = null,
  sidebar = false,
  sidebarTitleAccessor = 'title',
  sidebarStatAccessor = 'stat',
  SidebarComponent = null,
  children = null,
  ...rest
}) => {
  const handleSyncUpdatedAt = resource => {
    setResource({
      ...resource,
      updatedAt: resource.updatedAt,
    })
  }

  const calculatedCollectionPath = useMemo(
    () =>
      collectionPath
        ? typeof collectionPath === 'function'
          ? resource
            ? collectionPath(resource)
            : null
          : collectionPath
        : null,
    [collectionPath, resource]
  )

  const calculatedStoreCollectionId = useMemo(
    () =>
      storeCollectionId
        ? typeof storeCollectionId === 'function'
          ? resource
            ? storeCollectionId(resource)
            : null
          : storeCollectionId
        : null,
    [storeCollectionId, resource]
  )

  const Sidebar = SidebarComponent || OriginalSidebar

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {header ? (
        <div className={classes.header}>
          {typeof header === 'function' ? header(resource) : header}
        </div>
      ) : (
        <Title className={classes.header}>{translate('T_GENERAL_EDIT')}</Title>
      )}
      <Grid container spacing={3}>
        <Grid
          item
          xs={sidebar ? 9 : 12}
          className={clsx(sidebar && classes.grid)}
        >
          <Wrapper wrapWithPaper={false}>
            <BlankForm
              resource={resource}
              iri={iri}
              collectionPath={calculatedCollectionPath}
              editPath={editPath}
              storeCollectionId={calculatedStoreCollectionId}
              isCurrentResource={true}
              buttonsFixed={true}
              fetchCompareResource={boolean(
                process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
              )}
              {...rest}
            />
            {typeof children === 'function' ? children(resource) : children}
          </Wrapper>
        </Grid>
        {sidebar && (
          <Grid item xs={3}>
            <Wrapper wrapWithPaper={wrapWithPaper}>
              <Sidebar
                resource={resource}
                titleAccessor={sidebarTitleAccessor}
                statAccessor={sidebarStatAccessor}
                collectionPath={calculatedCollectionPath}
                handleSyncUpdatedAt={handleSyncUpdatedAt}
              />
            </Wrapper>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const Wrapper = ({ children, wrapWithPaper }) =>
  wrapWithPaper ? (
    <Paper withPadding={false}>{children}</Paper>
  ) : (
    <div>{children}</div>
  )
