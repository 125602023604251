import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { translate } from '_helpers/translate'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX_DATE'),
]

const useStyles = makeStyles({
  input: {
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const DateFilter = ({
  name,
  title,
  value,
  disabled,
  setFilter,
  classes = {},
}) => {
  const handleChange = value => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    setFilter(name, date || null)
  }

  const defaultClasses = useStyles()

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={LOCALES[process.env.REACT_APP_LOCALE]}
    >
      <div className={clsx(defaultClasses.input, classes.input)}>
        <KeyboardDatePicker
          id={`keyboard-id-${name}`}
          name={name}
          label={translate(title)}
          value={![null, undefined].includes(value) ? new Date(value) : null}
          disabled={disabled}
          disableToolbar
          format={FORMAT}
          minDate={false}
          invalidDateMessage={DATE_PICKER_ERRORS[0]}
          minDateMessage={DATE_PICKER_ERRORS[1]}
          maxDateMessage={DATE_PICKER_ERRORS[2]}
          onChange={handleChange}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}
