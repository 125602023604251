import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { NotFound } from 'pages'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { Loader } from 'components/Loader'
import { Paper } from 'components/Paper'
import { EmbeddedCollection } from 'components/embedded'
import { ChooseTable } from './components/choose_table/ChooseTable'
import { EditForm } from './components/EditForm'
import { UploadOrdCsvDialog } from './components/UploadOrdCsvDialog'
import { operations as selectableOperations } from 'pages/CollectionsThematic/components/_mass/delete'
import { operations } from 'components/table/_columns/operations'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useResourceState } from '_helpers/useResourceState'
import { commonConstants } from '_constants'
import { notification } from '_helpers/notification'
import { LANGS_IRI } from '_lib/langs'
import { translate } from '_helpers/translate'
import schema from '_schema/collection'
import exhibitSchema from '_schema/exhibit'
import routes from './routes'
import exhibitRoutes from 'pages/Exhibits/routes'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const useStyles = makeStyles({
  image_header: {
    fontSize: 20,
    marginBottom: 10,
  },
  exhibit_header: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  hint: {
    fontSize: 12,
    color: 'grey',
    marginBottom: 20,
  },
  ord_csv: {
    display: 'flex',
    marginTop: 20,
    '&>*': {
      paddingRight: 30,
      marginRight: 30,
      borderRight: '1px solid #aaa',
    },
    '&>*:last-child': {
      borderRight: 'none',
    },
  },
  ord_csv_state: {
    marginTop: 10,
  },
  ord_csv_pending: {
    fontWeight: 'bold',
    color: 'orange',
  },
  ord_csv_processing: {
    fontWeight: 'bold',
    color: 'blue',
  },
  ord_csv_finished: {
    fontWeight: 'bold',
    color: 'green',
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    null,
    dispatchOnFetch
  )

  const exhibitCollectionColumns = useMemo(
    () => [
      {
        header: 'Tytuł',
        accessor: `exhibit.translations.${LANGS_IRI.PL}.title`,
      },
      {
        header: 'ID',
        accessor: 'exhibit.museumId',
      },
      {
        ...operations(
          `exhibit.translations.${LANGS_IRI.PL}.title`,
          false,
          !resource?.isOrdCsvGenerationPending &&
            !resource?.isOrdCsvGenerationWaiting
        ),
        width: '13%',
      },
    ],
    [resource]
  )

  const [isOrdCsvFetching, setIsOrdCsvFetching] = useState(false)

  const handleOrdCsvGeneration = () => {
    setIsOrdCsvFetching(true)

    fetchDataHandleAuthError(
      iri,
      'PUT',
      { body: JSON.stringify({ startOrdCsvGeneration: true }) },
      response => {
        setIsOrdCsvFetching(false)

        setState.resource({
          ...resource,
          ordCsvGenerateState: response.ordCsvGenerateState,
        })

        notification('success', 'Kolekcja dodana do kolejki', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsOrdCsvFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <EditForm
      resource={resource}
      setResource={setState.resource}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties => {
        delete properties.idName

        return properties
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`}
      fieldsFullWidth={false}
      width={600}
    >
      <div className={classes.image_header}>Pliki pdf</div>
      <EmbeddedCollection
        endpoint={schema.subresources.files.endpoint}
        parentIri={iri}
        properties={schema.subresources.files.properties}
        definitionSchema={definitions[schema.subresources.files.definition]}
        titleAccessor={resource =>
          resource.translations[LANGS_IRI.PL].media?.originalName ||
          'brak pliku'
        }
        statable={true}
      />
      <div className={classes.exhibit_header}>Multimedia</div>
      <EmbeddedCollection
        endpoint={schema.subresources.multimedia.endpoint}
        parentIri={iri}
        properties={schema.subresources.multimedia.properties}
        definitionSchema={
          definitions[schema.subresources.multimedia.definition]
        }
        titleAccessor={resource =>
          resource.translations[LANGS_IRI.PL].title ||
          translate('T_GENERAL_MISSING_TITLE')
        }
        statable={false}
      />
      <div className={classes.exhibit_header}>Zdjęcie</div>
      <div className={classes.hint}>
        {
          'Dla etnografii wyświetli 4 pierwsze opublikowane zdjęcia a dla pozostałych tylko 1 zdjęcie'
        }
      </div>
      <EmbeddedCollection
        endpoint={schema.subresources.images.endpoint}
        parentIri={iri}
        properties={schema.subresources.images.properties}
        definitionSchema={definitions[schema.subresources.images.definition]}
        titleAccessor={resource =>
          resource.media?.originalName || 'brak zdjęcia'
        }
        statable={true}
      />
      <div className={classes.exhibit_header}>Dodaj obiekty do kolekcji</div>
      <ChooseTable
        parent={iri}
        endpoint={exhibitSchema.endpoint}
        viewPath={exhibitRoutes().view.path}
        isSelectable={!isOrdCsvCalculating(resource)}
      />
      <div className={classes.exhibit_header}>Obiekty dodane</div>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.subresources.exhibits.definition}
        endpoint={schema.subresources.exhibits.endpoint}
        columns={exhibitCollectionColumns}
        customResourceSchema={schema.resource}
        defaultFilters={{ 'iri[parent]': iri }}
        defaultSorters={{ ord: 'desc' }}
        storeCollectionId={`collection_exhibits_${iri}`}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={!isOrdCsvCalculating(resource)}
        selectable={
          !isOrdCsvCalculating(resource) ? [selectableOperations.delete()] : []
        }
        isRowLinkable={true}
        editPath={exhibitRoutes().view.path}
        autoWidth={false}
        uuidAccessor="exhibit.uuid"
      />
      <div className={classes.ord_csv}>
        <div>
          <Button
            variant="contained"
            color="secondary"
            disabled={isOrdCsvFetching || isOrdCsvCalculating(resource)}
            onClick={handleOrdCsvGeneration}
          >
            Generuj plik CSV z aktualną kolejnością
          </Button>
          <div className={classes.ord_csv_state}>
            Status przetwarzania:{' '}
            <span
              className={
                resource.ordCsvGenerateState?.value === 'pending'
                  ? classes.ord_csv_pending
                  : resource.ordCsvGenerateState?.value === 'processing'
                  ? classes.ord_csv_processing
                  : resource.ordCsvGenerateState?.value === 'finished'
                  ? classes.ord_csv_finished
                  : null
              }
            >
              {resource.ordCsvGenerateState?.value === 'pending'
                ? 'OCZEKUJE'
                : resource.ordCsvGenerateState?.value === 'processing'
                ? 'PRZETWARZANY'
                : resource.ordCsvGenerateState?.value === 'finished'
                ? 'UKOŃCZONY'
                : 'brak'}
            </span>
          </div>
          {resource.ordCsvGeneratedAt && (
            <div>
              Ostatnio wygenerowany plik:{' '}
              {moment(resource.ordCsvGeneratedAt).format(DATE_FORMAT)}
            </div>
          )}
          {resource.media &&
            resource.ordCsvGenerateState?.value === 'finished' && (
              <a
                href={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.media.url}`}
                download
              >
                Pobierz plik CSV
              </a>
            )}
        </div>
        <div>
          <UploadOrdCsvDialog
            iri={iri}
            resource={resource}
            setResource={setState.resource}
            disabled={isOrdCsvCalculating(resource)}
          />
          <div className={classes.ord_csv_state}>
            Status przetwarzania:{' '}
            <span
              className={
                resource.ordCsvProcessState?.value === 'pending'
                  ? classes.ord_csv_pending
                  : resource.ordCsvProcessState?.value === 'processing'
                  ? classes.ord_csv_processing
                  : resource.ordCsvProcessState?.value === 'finished'
                  ? classes.ord_csv_finished
                  : null
              }
            >
              {resource.ordCsvProcessState?.value === 'pending'
                ? 'OCZEKUJE'
                : resource.ordCsvProcessState?.value === 'processing'
                ? 'PRZETWARZANY'
                : resource.ordCsvProcessState?.value === 'finished'
                ? 'UKOŃCZONY'
                : 'brak'}
            </span>
          </div>
          {resource.ordCsvProcessedAt && (
            <div>
              Data przeprocesowania:{' '}
              {moment(resource.ordCsvProcessedAt).format(DATE_FORMAT)}
            </div>
          )}
          {resource.ordCsvProcessedMedia && (
            <div>
              Wgrany plik:{' '}
              <a
                href={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}${resource.ordCsvProcessedMedia.url}`}
                download
              >
                {resource.ordCsvProcessedMedia.originalName}
              </a>
            </div>
          )}
        </div>
      </div>
    </EditForm>
  )
}

const isOrdCsvGenerating = resource => {
  if (
    resource.ordCsvGenerateState?.value === 'pending' ||
    resource.ordCsvGenerateState?.value === 'processing'
  ) {
    return true
  }

  return false
}

const isOrdCsvProcessing = resource => {
  if (
    resource.ordCsvProcessState?.value === 'pending' ||
    resource.ordCsvProcessState?.value === 'processing'
  ) {
    return true
  }

  return false
}

const isOrdCsvCalculating = resource => {
  if (isOrdCsvGenerating(resource) || isOrdCsvProcessing(resource)) {
    return true
  }

  return false
}
